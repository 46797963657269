<template>
  <div :class="!topBarShow ? 'winningList-container active' : 'winningList-container'
    ">
    <van-nav-bar v-if="topBarShow" @click-left="goBack" left-arrow title="中奖记录" :border="false" />
    <div class="no-data" v-if="!winningList.length">暂无中奖记录</div>
    <div class="warpList" v-else>
      <div class="title">
        <p>中奖记录</p>
        <p>奖品名称</p>
        <p>状态</p>
      </div>
      <div class="contentListBigBox">
        <div class="contentList" v-for="(item, index) in winningList" :key="index">
          <div class="time">{{ item.created_at }}</div>
          <div class="goodsName">{{ item.goods_title }}</div>
          <div class="status">
            <p v-if="item.status == 0">
              <span v-if="item.type == 1 || item.type == 2">未发放</span>
              <span v-if="item.type == 3" class="daihexiao">
                <span v-if="item.order_no != null">未核销</span>
                <span v-if="item.order_no == null" @click="goXCX(index)">未发放</span>
              </span>
            </p>
            <p v-if="item.status == 1">
              <span v-if="item.type == 1 || item.type == 2">已发放</span>
              <span v-if="item.type == 3">已核销</span>
            </p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { NavBar, Toast } from "vant";
import { useStore } from "vuex";
import { isWeiChat } from "@/utils/environment";
import Api from "@/API";
export default {
  name: "winningList",
  components: {
    [NavBar.name]: NavBar,
  },
  setup(props) {
    //中奖记录列表
    let winningList = ref([]);
    //使用vuex
    const store = useStore();
    //使用路由
    const router = useRouter();
    //创建API对象
    const API = new Api();
    // 顶部栏显示隐藏
    const topBarShow = !isWeiChat();
    //返回
    function goBack() {
      router.back();
    }
    //初始化函数
    function init() {
      getWinningRecord();
    }
    //获取中奖记录
    const getWinningRecord = async () => {
      let res = await API.getBlindBoxPrizeLog({
        token: store.state.Token,
        luck_id: store.state.Luckid,
      });
      winningList.value = res.data;
    };
    // 去往小程序核销
    const goXCX = (index) => {
      console.log(winningList.value[index].sku, winningList.value[index].id)
      wx.miniProgram.navigateTo({
        'url': `/pages/receivePrizes/receivePrizes?winningInformation=${JSON.stringify(winningList.value[index].sku)}&blind_id=${winningList.value[index].id}`,
      });
    }
    onMounted(() => {
      init();
    });
    return {
      winningList,
      goBack,
      topBarShow,
      goXCX,
    };
  },
};
</script>

<style lang="scss">
:root {
  --van-nav-bar-icon-color: #000;
}

.winningList-container.active {
  .van-nav-bar {
    padding-top: 0;
  }
}

.winningList-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .van-nav-bar {
    padding-top: 50px;
  }

  .no-data {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 38px;
    font-weight: 600;
    color: #000;
    letter-spacing: 2px;
  }

  .warpList {
    padding: 30px;

    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        text-align: center;
        width: 33.33%;
        font-size: 30px;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #333333;
      }
    }

    .contentListBigBox {
      height: 1350px;
      overflow: auto;

      .contentList {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 26px;
        margin-bottom: 38px;

        div {
          text-align: center;
          width: 33.33%;
        }

        .time {
          font-size: 26px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #666666;
        }

        .goodsName {
          font-size: 26px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;
        }

        .status {
          font-size: 26px;
          font-family: Source Han Sans CN-Regular, Source Han Sans CN;
          font-weight: 400;
          color: #333333;

          .daihexiao {
            font-size: 26px;
            font-family: Source Han Sans CN-Regular, Source Han Sans CN;
            font-weight: 400;
            color: #FC3E5A;
          }
        }
      }
    }

  }
}
</style>